
import { mapState } from "vuex";

export default {
  data() {
    return {
      keyword: this.$route.query.keyword || "",
      indexType: this.$route.query.idxt || "clue",
      recommends: [
        "中茶",
        "下关",
        "蝴蝶白茶",
        "老同志",
      ],
      indexTypes: [
        // { name: "全部", type: "common" },
        { name: "内部订单", type: "clue" },
        { name: "产品", type: "product" },
        { name: "货主", type: "owner" },
      ]
    }
  },
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
      isAdminUser: (state) => state.isAdminUser,
      loadingUserInfo: (state) => state.loadingUserInfo,
    }),
  },
  methods: {
    handleChanged(){
      const {keyword} = this;
      if(this.$_.isEmpty(keyword)){
        return;
      }

      this.handleSearch();
    },
    handleSelectedRecommendWord(w){
      this.keyword = w;
      this.handleSearch();
    },
    handleSearch(){
      const {keyword, indexType} = this;
      const url = `/search/?keyword=${keyword}&idxt=${indexType}`;
      window.location.href = url;
    }
  }
}
