
import { mapState } from "vuex";
import clueApi from "~/utils/api/clue";
import metasMixins from "~/mixins/metas";
import format from "~/utils/format";

export default {
  mixins: [metasMixins],
  layout: "client",
  data() {
    return {
      clueUserType: "all",
      userID: null,
      status: 0, // 客户只能看到进行中的
      name: '',
      clues: [],
      loading: false
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getClues();
    });
  },
  computed: {
    ...mapState("users", {
      user: (state) => state.user,
    }),
  },
  methods: {
    async getClues(targetMetas) {
      this.loading = true;

      try {
        const { name, status, userID, metas } = this;
        const willLoadMetas = targetMetas || metas;
        let data = {
          ...willLoadMetas,
          name,
          status,
          userID
        };


        const rs = await clueApi.listLite({
          ...data
        });
        if (rs) {
          this.metas = rs.metas;
          this.clues = rs.data || [];
        }
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    // 分页变化
    async handleCurrentChange(val) {
      const { metas } = this;
      let willLoadMeta = { ...metas };
      willLoadMeta.currentPage = val;

      await this.getClues(willLoadMeta);
    },
    /**
     * 筛选器状态变化
     * @param {*} status 
     */
    async handleClueStatusFilterChange(status) {
      this.status = status;
      await this.getClues();
    },
    // 跳转到引导创建
    handleClickCreate() {
      this.$router.push("/manager/setup");
    },
    /**
     * 格式化时间
     * @param {*} d 
     */
    formatDate(d) {
      return format.formatDate(d);
    },
  }
}
