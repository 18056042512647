
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: Number,
      default: null,
    }
  },
  computed: {
    ...mapState("bootstrap", { clueTypes: (state) => state.clueTypes }),
    clueTypesReverse() {
      const { clueTypes } = this;

      let types = [];
      Object.keys(clueTypes)
        .sort()
        .reverse()
        .forEach((key) => {
          types.push({ name: clueTypes[key], value: key })
        })

      return types

    }
  },
  data() {
    return {
      clueType: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.autoCompl();
    });
  },
  methods: {
    handleChange(val) {
      const { clueTypes } = this;

      let value = null;
      for (const key in clueTypes) {
        if (clueTypes[key] == val) {
          value = key;
        }
      }

      this.$emit('input', Number(value));
    },
    // 自动回显
    autoCompl() {
      const { clueTypes, value } = this;

      this.clueType = clueTypes[value] || null;
    }
  },
  watch: {
    "value": {
      handler() {
        this.autoCompl();
      },
      immediate: true
    }
  }
}
