import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3284f00c&scoped=true&lang=pug&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=3284f00c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3284f00c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBanner: require('/Users/virs/Chayou/chenchacang_web/components/search/Banner.vue').default,ClueUserSaveButton: require('/Users/virs/Chayou/chenchacang_web/components/clue/user/SaveButton.vue').default,UiPageHeader: require('/Users/virs/Chayou/chenchacang_web/components/ui/PageHeader.vue').default,ClueListTile: require('/Users/virs/Chayou/chenchacang_web/components/clue/ListTile.vue').default,UiView: require('/Users/virs/Chayou/chenchacang_web/components/ui/View.vue').default})
