import req from "~/utils/request";
import { URLS } from "~/utils/request/requestUrls";

export default {
  /// 保存
  async save(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_SAVE,
      data
    });
    return rs.data;
  },
  // 列表
  async list(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_LIST,
      data
    });
    return rs.data;
  },
  //- 列表简版
  async listLite(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_LIST_LITE,
      data
    });
    return rs.data;
  },
  // 修改状态
  async status(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_STATUS,
      data
    });
    return rs.data;
  },
  // 删除
  async delete(id) {
    const rs = await req.get({
      url: `${URLS.CLUE_DELETE}/${id}`
    });
    return rs.data;
  },
  //- 详情
  async detail(id) {
    const rs = await req.get({
      url: `${URLS.CLUE_DETAIL}/${id}`
    });
    return rs.data;
  },
  //- 详情简版
  async detailLite(id) {
    const rs = await req.get({
      url: `${URLS.CLUE_LITE_DETAIL}/${id}`
    });
    return rs.data;
  },
  //- 保存跟进记录
  async historySave(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_HISTORY_SAVE,
      data
    });
    return rs.data;
  },
  //- 删除跟进记录
  async historyDelete(id) {
    const rs = await req.get({
      url: `${URLS.CLUE_HISTORY_DELETE}/${id}`
    });
    return rs.data;
  },
  //- 关联货主
  async ownerBind(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_OWNER_BIND,
      data
    });
    return rs.data;
  },
  //- 关联产品
  async productBind(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_PRODUCT_BIND,
      data
    });
    return rs.data;
  },
  //- 关联仓储
  async warehouseBind(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_WAREHOUSE_BIND,
      data
    });
    return rs.data;
  },
  // 修改用户发布的状态
  async statusUser(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_USER_STATUS,
      data
    });
    return rs.data;
  },
  // 删除用户发布的
  async deleteUser(id) {
    const rs = await req.get({
      url: `${URLS.CLUE_USER_DELETE}/${id}`
    });
    return rs.data;
  },
  //- 保存用户发布的
  async saveUser(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_USER_SAVE,
      data
    });
    return rs.data;
  },
  //- 列出用户发布的
  async listUser(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_USER_LIST,
      data
    });
    return rs.data;
  },
  //- 认领
  async claim(data) {
    const rs = await req.postJson({
      url: URLS.CLUE_CLAIM,
      data
    });
    return rs.data;
  },
  /**
   * 订单保护
   * @param {*} id 
   * @returns 
   */
  async protected(id, data) {
    const rs = await req.postJson({
      url: `${URLS.CLUE_PROTECTED}/${id}`,
      data
    });
    return rs.data;
  },
}