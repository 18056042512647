
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    }
  },
  data() {
    return {}
  }
}
