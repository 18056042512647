
import clueApi from "~/utils/api/clue";

const defaultFormData = {
  clueType: 2,
  name: "",
  mobile: null,
  content: ""
}

export default {
  props: {
    userClue: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      // 对话框可见
      dialogVisible: false,
      loading: false,
      formData: {
        ...defaultFormData
      },
      rules: {
        name: [
          { required: true, message: '请输入产品名称', trigger: 'blur' },
          { min: 2, max: 100, message: '长度在 2 到 100 个字符', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ],
      }
    }
  },
  computed: {
    isEditMode() {
      const { userClue } = this;
      return this.$_.isEmpty(userClue) ? false : true;
    }
  },
  methods: {
    handleCreateUserClue() {
      this.dialogVisible = true;
    },
    handleDialogClose() {
      this.dialogVisible = false;
      this.$emit('closed');
    },
    handleSave() {
      this.$refs["saveUserClueForm"].validate(async (valid) => {
        // 校验
        if (!valid) {
          return;
        }

        this.loading = true;

        try {
          const { formData } = this;

          const rs = await clueApi.saveUser({
            ...formData,
            mobile: formData.mobile
          });
          if (rs) {
            this.$notify({
              title: '创建需求成功',
              message: '请保持手机畅通，我们已经通知销售尽快与您取得联系',
              type: 'success'
            });
            this.formData = {
              ...defaultFormData
            }
            this.handleDialogClose();
            this.$emit("added", rs.data || null);
          }

        } catch (err) {
          console.error(err);
        }

        this.loading = false;
      });
    }
  }
}
